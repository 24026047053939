(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/game-receipt/assets/javascripts/game-receipt.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/game-receipt/assets/javascripts/game-receipt.js');

'use strict';

const {
  format
} = svs.utils;
const {
  formatDate: lbUtilsHelpers
} = svs.components.lbUtils.helpers;
const {
  games: gamesHelpers
} = svs.components.marketplace.helpers;
const {
  getStatusDesc
} = svs.components.marketplace.helpers.games;
const {
  GAME_STATUS_FINISHED,
  GAME_STATUS_SUBMITTED
} = svs.components.marketplaceData.gamesConstants;
const getGameReceipt = (game, team) => {
  const {
    buyStop,
    commissionPercent,
    fractionsCount,
    fractionPrice,
    isSoldOut,
    isAllowedToSubmit,
    isSubmitted,
    maxFractions,
    ownerName,
    playableAmount,
    status
  } = game;
  const isRetailer = (team === null || team === void 0 ? void 0 : team.type) === 'RETAILER';
  const isLocked = [GAME_STATUS_SUBMITTED, GAME_STATUS_FINISHED].indexOf(status) > -1;
  const shouldShowStatus = new Date(buyStop).getTime() < Date.now() || isSoldOut || isSubmitted;
  const rows = [];

  const renderAmount = function (value) {
    let empty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return value ? "".concat(format.Currency(value), " kr") : empty;
  };
  if (isRetailer) {
    if (isLocked) {
      const {
        wagerAmount,
        wagerSharePrice,
        soldShares,
        shareCommission
      } = game;
      rows.push([{
        title: 'Insats per andel',
        text: renderAmount(wagerSharePrice)
      }, {
        title: 'Status',
        text: getStatusDesc(status)
      }]);
      rows.push([{
        title: 'Spelläggare',
        text: ownerName
      }, {
        title: 'Butiksavgift',
        text: renderAmount(shareCommission)
      }]);
      rows.push([{
        title: 'Andelar',
        text: soldShares
      }, {
        title: 'Lagt spel',
        text: renderAmount(wagerAmount)
      }]);
      return rows;
    }
    rows.push([{
      title: 'Andelspris',
      text: renderAmount(fractionPrice)
    }, {
      title: shouldShowStatus ? 'Spelstatus' : 'Sista andelsköp',
      text: shouldShowStatus ? "".concat(gamesHelpers.getStatusDesc(status, isAllowedToSubmit)).concat(isSoldOut ? '*' : '') : lbUtilsHelpers.formatDate(buyStop, true, true, true)
    }]);
    rows.push([{
      title: 'Spelläggare',
      text: ownerName
    }, {
      title: 'Butiksavgift',
      text: commissionPercent ? "".concat(commissionPercent, " % p\xE5 lagt spel") : null
    }]);
    rows.push([{
      title: 'Andelar',
      text: maxFractions
    }, {
      text: 'Om färre andelar säljs kan spelet minskas eller återbetalas.',
      options: {
        className: 'view-header-receipt-column-3',
        textClassName: 'f-200'
      }
    }]);
    return rows;
  }
  const {
    fractionCommittedPrice,
    wagerAmount
  } = game;
  rows.push([{
    title: isSubmitted ? 'Insats per andel' : 'Andelspris',
    text: renderAmount(isSubmitted ? fractionCommittedPrice : game.fractionPrice, '-')
  }, {
    title: shouldShowStatus ? 'Spelstatus' : 'Sista andelsköp',
    text: shouldShowStatus ? "".concat(gamesHelpers.getStatusDesc(status, isAllowedToSubmit)).concat(isSoldOut ? '*' : '') : lbUtilsHelpers.formatDate(buyStop, true, true, true)
  }]);
  rows.push([{
    title: 'Sålda andelar',
    text: "".concat(fractionsCount).concat(isSoldOut ? '*' : '')
  }, {
    title: isSubmitted ? 'Lagt spel' : 'Att spela för',
    text: renderAmount(isSubmitted ? wagerAmount : playableAmount, '-')
  }]);
  if (!isRetailer) {
    rows.push([{
      title: 'Andelar',
      text: maxFractions
    }, {
      text: 'Om färre andelar säljs kan spelet minskas eller återbetalas.',
      options: {
        className: 'view-header-receipt-column-3',
        textClassName: 'f-200'
      }
    }]);
  }
  return rows;
};
setGlobal('svs.components.marketplaceShared.gameReceipt.getGameReceipt', getGameReceipt);

 })(window);